import React from "react";
import { ethers } from "ethers";
import { PrivateUserType, ContractsInfo } from "root/lib/graphqlDefs";
import create, { SetState } from "zustand";
import { combine } from "zustand/middleware";
import detectEthereumProvider from "@metamask/detect-provider";
import { deleteToken } from "root/lib/clientAuth";
import ErrorModal from "root/components/ErrorModal";
import buildCombinedContracts, {
  CombinedContracts,
} from "root/hooks/useEthereum/buildCombinedContracts";
import modalsStore from "../useModals";

interface StateType {
  ethersProvider?: ethers.providers.Web3Provider;
  ethAddress?: string;
  error?: string;
  contracts?: CombinedContracts;
}

// Define a ethers global variable outside so we can attach listeners
// and have them not die when the store removes the ethers from the store
// on errors
let ethersProvider: ethers.providers.Web3Provider;
let inProgress = false;

const defaultState: StateType = {
  ethersProvider: null,
  ethAddress: null,
  error: null,
  contracts: null,
};
const actions = (set: SetState<StateType>) => ({
  initialize: async (contractsInfo: ContractsInfo, user?: PrivateUserType) => {
    return;
    async function handleError(error: string) {
      modalsStore.getState().renderModal({
        content: (
          <ErrorModal
            onClose={modalsStore.getState().closeModal}
            message={error}
          />
        ),
        label: "Error",
        disableDismiss: true,
      });

      set(
        (state) => ({
          ...state,
          ethersProvider: null,
          ethAddress: null,
          error,
        }),
        true
      );
    }

    if (inProgress) return;

    inProgress = true;

    try {
      const provider = await detectEthereumProvider();

      if (provider) {
        ethersProvider = new ethers.providers.Web3Provider(provider);
        await ethersProvider.send("eth_requestAccounts", []);

        const ethAddress = await ethersProvider.getSigner().getAddress();

        if (user && user.ethAddress !== ethAddress) {
          modalsStore.getState().renderModal({
            content: (
              <ErrorModal message="You are logged in with another wallet address. Please refresh the page!" />
            ),
            label: "Error",
            disableDismiss: true,
          });

          await deleteToken();
        }

        const { chainId } = await ethersProvider.getNetwork();
        const chainIsValid = contractsInfo.chainId === chainId;
        const contracts = await buildCombinedContracts(
          ethersProvider,
          contractsInfo
        );

        if (chainIsValid) {
          set(
            (state) => ({
              ...state,
              error: null,
              ethersProvider,
              ethAddress,
              contractsInfo,
              contracts,
            }),
            true
          );
        } else {
          handleError(
            `You are on the wrong Ethereum network! Please change to
              ${contractsInfo.chainName} network and refresh the page.`
          );
        }
      } else {
        handleError(
          "Can't detect provider! Please install Metamask, refresh and try again"
        );
      }
    } catch (error) {
      console.error(error);
      handleError(
        "Unhandled error. Please refresh and if the issue persists contact support"
      );
    }

    inProgress = false;
  },
});

const useEthereumStore = create(combine(defaultState, actions));

export const useEthereumInitializer = () =>
  useEthereumStore((state) => state.initialize);

export type { CombinedContracts };

export default function useEthereum() {
  const ethereumStore = useEthereumStore();

  return {
    ethersProvider: ethereumStore.ethersProvider,
    ethAddress: ethereumStore.ethAddress,
    contracts: ethereumStore.contracts,
    error: ethereumStore.error,
  };
}
