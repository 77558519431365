/* eslint-disable no-console */
import React, { useEffect } from "react";
import Script from "next/script";
import { pageview } from "root/lib/analytics";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/nextjs";
import { ServerStateProvider } from "root/hooks/useServerState";
import { useNotificationsInitializer } from "root/hooks/useNotifications";
import { useEthereumInitializer } from "root/hooks/useEthereum";
import { ModalProvider } from "root/hooks/useModals";
import { LightboxProvider } from "root/hooks/useLightbox";
import SiteLoader from "root/components/SiteLoader";
import BaseHead from "root/components/BaseHead";
import buildGlobalRedaxios from "root/utils/buildGlobalRedaxios";
import { initializeUseIsTransitioning } from "root/hooks/useIsTransitioning";
import {
  CurrentUserType,
  ContractsInfo,
  RegisteredContract,
} from "root/lib/graphqlDefs";
import GlobalStyle from "../config/globalStyles";
import theme from "../config/theme";
import Layout from "../components/Layout/Layout";
import Custom500 from "./500";

import "react-aspect-ratio/aspect-ratio.css";
import "root/styles/fonts.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

buildGlobalRedaxios();

function MyApp({ Component, pageProps }) {
  const user = pageProps.user as CurrentUserType;
  const contractsInfo = pageProps.contractsInfo as ContractsInfo;
  const registeredContracts =
    pageProps.registeredContracts as RegisteredContract[];
  const ethPriceInUsd = pageProps.ethPriceInUsd as number;
  const initializeNotifications = useNotificationsInitializer();
  const initializeEthereum = useEthereumInitializer();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    (window.ethereum as any)?.on(
      "accountsChanged",
      async (_accounts: string[]) => {
        console.log("accountsChanged");
        initializeEthereum(contractsInfo, user);
      }
    );

    (window.ethereum as any)?.on("chainChanged", (_chainId: number) => {
      console.log("chainChanged");
      initializeEthereum(contractsInfo, user);
    });

    (window.ethereum as any)?.on(
      "disconnect",
      async (_code: number, _reason: string) => {
        console.log("disconnect");
        initializeEthereum(contractsInfo, user);
      }
    );

    initializeUseIsTransitioning();
  }, []);

  useEffect(() => {
    if (user) {
      initializeNotifications(user.notifications);
      initializeEthereum(contractsInfo, user);
      Sentry.configureScope((scope) => {
        scope.setTag("username", `${user.username}`);
        scope.setTag("ethAddress", `${user.ethAddress}`);
      });
    } else {
      Sentry.configureScope((scope) => {
        scope.setTag("username", "anonymous");
      });
    }
  }, [contractsInfo, initializeEthereum, initializeNotifications, user]);

  // We can do this outside of an effect because
  // it doesn't trigger UI updates
  // and we must do it ASAP
  buildGlobalRedaxios(user);

  return (
    <ThemeProvider theme={theme}>
      {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            strategy="lazyOnload"
          />
          <Script
            dangerouslySetInnerHTML={{
              __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                      page_path: window.location.pathname,
                    });
                  `,
            }}
          />
        </>
      )}

      <GlobalStyle />
      <Sentry.ErrorBoundary
        fallback={
          <Layout>
            <Custom500 />
          </Layout>
        }
      >
        <BaseHead metadata={pageProps.socialMetadata} />
        <ServerStateProvider
          state={{ user, contractsInfo, ethPriceInUsd, registeredContracts }}
        >
          <QueryClientProvider client={queryClient}>
            <ModalProvider>
              <LightboxProvider>
                <SiteLoader {...pageProps} />
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </LightboxProvider>
            </ModalProvider>
          </QueryClientProvider>
        </ServerStateProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
}

export default MyApp;
