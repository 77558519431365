import { BigNumber, ethers, providers } from "ethers";
import { ListingType, ProposalType, ContractsInfo } from "root/lib/graphqlDefs";

const ASSET_ABI = [
  "function mint(address to, uint256 amount, uint256 _tokenType) public virtual",
  "function burn(address account, uint256 tokenId, uint256 value)",
  "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data)",
  "function addMinterRole(address account)",
  "function revokeMinterRole(address account)",
  "function addModeratorRole(address account)",
  "function revokeModeratorRole(address account)",
  "function setApprovalForAll(address operator, bool approved)",
  "function isApprovedForAll(address account, address operator) view returns (bool)",
  "function hasRole(bytes32 role, address account) public view virtual override returns (bool)",
];
const PROPOSAL_SALE_ABI = [
  "function list(uint256 _assetId, uint256 _amount, address[] memory _beneficiaries, uint256[] memory _values) public",
  "function propose(uint256 _listingId, uint256 _amount) public payable returns (bool)",
  "function accept(uint256 _listingId, uint256 _proposalId) public returns (bool)",
  "function cancelPropose(uint256 _listingId, uint256 _proposalId) public",
  "function cancelListing(uint256 _listingId) public",
];
const DIRECT_SALE_ABI = [
  "function buy(uint256 _listingId, uint256 _amount) public payable returns (bool)",
  "function list(uint256 _assetId, uint256 _amount, uint256 _price, address[] memory _beneficiaries, uint256[] memory _values) public",
  "function cancel(uint256 _listingId) public",
];
const AUCTION_SALE_ABI = [
  "function list(uint256 tokenId, uint256 amount, uint256 reservePrice, uint256 _duration, address[] memory _beneficiaries, uint256[] memory _values) public",
  "function bid(uint256 auctionId) public payable",
  "function cancel(uint256 auctionId) public",
  "function settle(uint256 auctionId) public",
  "function getMinBidAmount(uint256 auctionId) public view returns (uint256)",
];

const PAYABLE_EXTENSION_ABI = [
  "function mint(bytes32[] calldata merkleProof) external payable",
  "function mintPrice() public view returns (uint96)",
];

const ASSET_TYPE_TO_INT: Record<string, number> = {
  ARTWORK: 0,
  DIGITAL_COLLECTIBLE: 1,
  PHYGITAL_COLLECTIBLE: 2,
  PHYGITAL_ARTWORK: 3,
  EXTRA: 4,
};

export interface ExtensionContract {
  mint: (
    merkleProof: string[],
    price: number
  ) => Promise<providers.TransactionResponse>;
  getMintPrice: () => Promise<BigNumber>;
}

export interface OldDissrupContract {
  mint: (
    quantity: number,
    type?: string
  ) => Promise<providers.TransactionResponse>;

  burn: (
    assetId: string,
    quantity: number
  ) => Promise<providers.TransactionResponse>;

  transfer: (
    recipientAddress: string,
    assetId: string,
    quantity: number
  ) => Promise<providers.TransactionResponse>;

  addMinterRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  revokeMinterRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  addModeratorRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  revokeModeratorRole: (
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  hasRole: (
    role: string,
    recipientAddress: string
  ) => Promise<providers.TransactionResponse>;

  listDirectSale: (
    assetId: string,
    quantity: number,
    price: number | string
  ) => Promise<providers.TransactionResponse>;

  buyDirectSale: (
    listing: ListingType,
    quantity: number
  ) => Promise<providers.TransactionResponse>;

  cancelDirectSale: (
    listing: ListingType
  ) => Promise<providers.TransactionResponse>;

  listProposalSale: (
    assetId: string,
    quantity: number
  ) => Promise<providers.TransactionResponse>;

  cancelProposalSale: (
    listing: ListingType
  ) => Promise<providers.TransactionResponse>;

  makeProposal: (
    listing: ListingType,
    quantity: number,
    price: number | string
  ) => Promise<providers.TransactionResponse>;

  acceptProposal: (
    proposal: ProposalType
  ) => Promise<providers.TransactionResponse>;

  cancelProposal: (
    proposal: ProposalType
  ) => Promise<providers.TransactionResponse>;

  listAuctionSale: (
    assetId: string,
    quantity: number,
    reservePrice: number,
    duration: number
  ) => Promise<providers.TransactionResponse>;

  placeBid: (
    listing: ListingType,
    price: number
  ) => Promise<providers.TransactionResponse>;

  settleAuctionSale: (
    listing: ListingType
  ) => Promise<providers.TransactionResponse>;

  auctionMinBid: (
    listing: ListingType
  ) => Promise<providers.TransactionResponse>;

  cancelAuctionSale: (
    listing: ListingType
  ) => Promise<providers.TransactionResponse>;
}

export function buildPayableExtensionContract(
  provider: ethers.providers.Web3Provider,
  contractAddress: string
): ExtensionContract {
  const signer = provider.getSigner();
  const extensionContract = new ethers.Contract(
    contractAddress,
    PAYABLE_EXTENSION_ABI,
    signer
  );

  return {
    mint: (merkleProof, price) =>
      extensionContract.mint(merkleProof, {
        value: ethers.utils.parseEther(price.toString()),
      }),
    getMintPrice: () => extensionContract.mintPrice(),
  };
}

export default async function buildOldDissrupContracts(
  provider: ethers.providers.Web3Provider,
  contractsInfo: ContractsInfo
): Promise<OldDissrupContract> {
  const signer = provider.getSigner();
  const fromAddress = await signer.getAddress();
  const assetContract = new ethers.Contract(
    contractsInfo.assetContract,
    ASSET_ABI,
    signer
  );
  const proposalContract = new ethers.Contract(
    contractsInfo.proposalSaleContract,
    PROPOSAL_SALE_ABI,
    signer
  );
  const directSaleContract = new ethers.Contract(
    contractsInfo.directSaleContract,
    DIRECT_SALE_ABI,
    signer
  );
  const auctionSaleContract = new ethers.Contract(
    contractsInfo.auctionSaleContract,
    AUCTION_SALE_ABI,
    signer
  );

  return {
    mint: (quantity, assetType) =>
      assetContract.mint(
        fromAddress,
        quantity,
        ASSET_TYPE_TO_INT[assetType] || 0
      ),

    burn: (assetId, quantity) =>
      assetContract.burn(fromAddress, assetId, quantity),

    transfer: (recipientAddress, assetId, quantity) =>
      assetContract.safeTransferFrom(
        fromAddress,
        recipientAddress,
        assetId,
        quantity,
        []
      ),

    addMinterRole: (recipientAddress) =>
      assetContract.addMinterRole(recipientAddress),

    revokeMinterRole: (recipientAddress) =>
      assetContract.revokeMinterRole(recipientAddress),

    addModeratorRole: (recipientAddress) =>
      assetContract.addModeratorRole(recipientAddress),

    revokeModeratorRole: (recipientAddress) =>
      assetContract.revokeModeratorRole(recipientAddress),

    hasRole: (role, recipientAddress) =>
      assetContract.hasRole(role, recipientAddress),

    listDirectSale: async (assetId, quantity, price) =>
      directSaleContract.list(
        assetId,
        quantity,
        ethers.utils.parseEther(price.toString()),
        [fromAddress],
        [85]
      ),

    buyDirectSale: async (_, _quantity) => null,
    cancelDirectSale: async (listing) =>
      directSaleContract.cancel(listing.blockchainId),

    listProposalSale: async (assetId, quantity) =>
      proposalContract.list(assetId, quantity, [fromAddress], [85]),

    cancelProposalSale: async (listing) =>
      proposalContract.cancelListing(listing.blockchainId),

    makeProposal: (listing, quantity, price) =>
      proposalContract.propose(
        listing.blockchainId,
        ethers.BigNumber.from(quantity),
        {
          value: ethers.utils.parseEther(price.toString()),
        }
      ),

    acceptProposal: (proposal) =>
      proposalContract.accept(
        proposal.listing.blockchainId,
        proposal.blockchainId
      ),

    cancelProposal: (proposal) =>
      proposalContract.cancelPropose(
        proposal.listing.blockchainId,
        proposal.blockchainId
      ),

    listAuctionSale: (assetId, quantity, reservePrice, duration) =>
      auctionSaleContract.list(
        assetId,
        quantity,
        ethers.utils.parseEther(reservePrice.toString()),
        duration,
        [fromAddress],
        [85]
      ),

    placeBid: (listing, price) =>
      auctionSaleContract.bid(listing.blockchainId, {
        value: ethers.utils.parseEther(price.toString()),
      }),

    settleAuctionSale: (listing) =>
      auctionSaleContract.settle(listing.blockchainId),

    auctionMinBid: (listing) =>
      auctionSaleContract.getMinBidAmount(listing.blockchainId),

    cancelAuctionSale: (listing) =>
      auctionSaleContract.cancel(listing.blockchainId),
  };
}
