import { gql } from "graphql-request";
import {
  AssetGql,
  CollectionGql,
  ExtendedAssetGql,
  NotificationType,
  PrivateUserType,
  ShipmentStatusEnum,
} from "./graphqlDefs";

export interface UserUpdateParams {
  name?: string;
  username?: string;
  email?: string;
  avatar?: FileList;
  hiddenAssetsFromGallery?: string[];
}

export async function updateUser(params: UserUpdateParams) {
  const formData = new FormData();
  const { avatar, ...paramsWithoutFiles } = params;

  formData.append("params", JSON.stringify(paramsWithoutFiles));

  if (avatar) formData.append("avatar", avatar[0]);
  else if (avatar === null) formData.append("avatar", "null");

  const response = await authenticatedRedaxios(`/users`, {}, "PATCH", formData);

  return response.data;
}

export async function toggleAssetVisibility(
  blockchainId: string
): Promise<PrivateUserType> {
  const response = await authenticatedRedaxios<PrivateUserType>(
    `/users/toggle_asset_visibility/${blockchainId}`,
    {},
    "PATCH"
  );

  return response.data;
}

export async function updateUserGallery(params) {
  const formData = new FormData();
  const { coverMedia, portraitImage, additionalImages, ...paramsWithoutFiles } =
    params;

  formData.append("params", JSON.stringify(paramsWithoutFiles));

  if (coverMedia && !coverMedia.fake) formData.append("coverMedia", coverMedia);
  else if (!coverMedia) formData.append("coverMedia", "null");

  if (portraitImage && !portraitImage.fake) {
    formData.append("portraitImage", portraitImage);
  } else if (!portraitImage) formData.append("portraitImage", "null");

  if (additionalImages) {
    [
      ...additionalImages,
      ...Array.from({ length: 5 - additionalImages.length }),
    ].forEach((image, index) => {
      if (image && !image.fake) {
        formData.append(`additionalImages[${index}]`, image);
      } else if (!image) formData.append(`additionalImages[${index}]`, "null");
    });
  }

  const response = await authenticatedRedaxios.put("/user_galleries", formData);

  return response.data;
}

export async function updateNotification(
  id: string,
  args: { isRead?: boolean } = {}
): Promise<NotificationType> {
  const response = await authenticatedRedaxios<NotificationType>(
    `/notifications/${id}`,
    {},
    "PATCH",
    args
  );

  return response.data;
}

export interface UploadAssetResult {
  name: string;
  cid: string;
  size: string;
}

export async function uploadAsset(
  file: File,
  { onProgress }: { onProgress?: (completedPercentage: number) => void } = {}
): Promise<UploadAssetResult> {
  if (!file) return undefined;

  const formData = new FormData();

  formData.append("file", file);

  return new Promise<UploadAssetResult>((resolve, reject) => {
    const request = new XMLHttpRequest();

    if (onProgress) {
      request.upload.addEventListener("progress", (event) => {
        onProgress((event.loaded / event.total) * 100);
      });
    }

    request.addEventListener("load", () => {
      if (request.status === 200) {
        resolve(JSON.parse(request.response));
      } else reject(request);
    });

    request.addEventListener("error", reject);

    request.open(
      "POST",
      `${authenticatedRedaxios.defaults.baseURL}/assets/media`
    );
    request.setRequestHeader(
      "Authorization",
      authenticatedRedaxios.defaults.headers.authorization
    );
    request.send(formData);
  });
}

export async function createAsset(
  args: Record<string, unknown>
): Promise<unknown> {
  const response = await authenticatedRedaxios.post("/assets", args);

  return response.data;
}

export async function updateShipment(
  id: string,
  args: { status?: ShipmentStatusEnum } = {}
): Promise<unknown> {
  const response = await authenticatedRedaxios(
    `/shipments/${id}`,
    {},
    "PATCH",
    args
  );

  return response.data;
}

export async function subscribeMailchimp(email: string) {
  await graphqlClient.request(
    gql`
      mutation ($email: String!) {
        subscribeNewsletter(email: $email)
      }
    `,
    { email }
  );
}

export async function likeAsset(blockchainId: string) {
  await graphqlClient.request(
    gql`
      mutation ($blockchainId: String!) {
        likeAsset(blockchainId: $blockchainId) {
          id
        }
      }
    `,
    { blockchainId }
  );
}

export async function selfRemoveRoles() {
  await graphqlClient.request(
    gql`
      mutation {
        selfRemoveRoles
      }
    `
  );
}

export async function unlikeAsset(blockchainId: string) {
  await graphqlClient.request(
    gql`
      mutation ($blockchainId: String!) {
        unlikeAsset(blockchainId: $blockchainId) {
          id
        }
      }
    `,
    { blockchainId }
  );
}

export async function followUser(userId: string) {
  await graphqlClient.request(
    gql`
      mutation ($userId: String!) {
        followUser(userId: $userId) {
          id
        }
      }
    `,
    { userId }
  );
}

export async function unfollowUser(userId: string) {
  await graphqlClient.request(
    gql`
      mutation ($userId: String!) {
        unfollowUser(userId: $userId) {
          id
        }
      }
    `,
    { userId }
  );
}

export async function verifyTransfer(txHash: string, contractAddress: string) {
  await graphqlClient.request(
    gql`
      mutation ($txHash: String!, $contractAddress: String!) {
        verifyTransfer(
          transactionHash: $txHash
          contractAddress: $contractAddress
        )
      }
    `,
    { txHash, contractAddress }
  );
}

export interface HighestProposalResult {
  price: string;
}

export async function getListingHighestProposal(
  listingId: string
): Promise<HighestProposalResult> {
  const response = await graphqlClient.request(
    gql`
      query ($listingId: String!) {
        proposals(
          where: { listing: $listingId }
          orderBy: price
          orderDirection: desc
          first: 1
        ) {
          price
        }
      }
    `,
    { listingId }
  );

  return response.proposals?.[0];
}

export async function getAuctionInformation(
  listingId: string
): Promise<unknown> {
  const response = await graphqlClient.request(
    gql`
      query ($listingId: ID!) {
        listings(where: { listingType: auction, id: $listingId }) {
          price
          endTime
        }
      }
    `,
    { listingId }
  );

  return response.listings?.[0];
}

export async function getAuctionLastBid(listing: any): Promise<unknown> {
  return listing.bids?.[0];
}

export async function updateAlgoliaData(): Promise<unknown> {
  await graphqlClient.request(
    gql`
      mutation {
        updateAlgolia
      }
    `
  );

  return true;
}

export async function getTrendingCollectionsBySlug(slugs: string[]) {
  const req = await graphqlClient.request(
    gql`
        query ($slugs: [String]) {
          collections(where: {slug_in: $slugs}) {
            ${CollectionGql}
          }
        }
      `,
    {
      slugs,
    }
  );

  return req.collections;
}

export async function getCollectionsByDate(
  direction: string,
  skip: number,
  pageSize: number
) {
  const req = await graphqlClient.request(
    gql`
        query (
          $first: Int
          $skip: Int
          $direction: OrderDirection
        ) {
          collections(
            first: $first
            skip: $skip
            orderBy: "createdAt"
            orderDirection: $direction
          ) {
            ${CollectionGql}
          }
        }
      `,
    {
      first: pageSize,
      direction,
      skip,
    }
  );

  return req.collections;
}

export async function getCollectionAssets(collectionSlug: string) {
  const req = await graphqlClient.request(
    gql`
        query ($collectionSlug: String) {
          collection(slug: $collectionSlug) {
            assets(customOrder: true) {
              ${AssetGql}
            }
          }
        }
      `,
    {
      collectionSlug,
    }
  );

  return req?.collection?.assets || [];
}

export async function createCollection(
  title: string,
  description: string,
  longDescription: string,
  slug: string,
  coverMediaCid: string,
  customOrder: string[],
  assets: string[]
): Promise<{ slug: string }> {
  const response = await graphqlClient.request(
    gql`
      mutation (
        $title: String!
        $description: String!
        $longDescription: String!
        $slug: String!
        $coverMediaCid: String!
        $customOrder: [String]!
        $assets: [String]!
      ) {
        createCollection(
          title: $title
          description: $description
          longDescription: $longDescription
          slug: $slug
          coverMediaCid: $coverMediaCid
          customOrder: $customOrder
          assets: $assets
        ) {
          slug
        }
      }
    `,
    {
      title,
      description,
      longDescription,
      slug,
      coverMediaCid,
      customOrder,
      assets,
    }
  );

  return response.createCollection;
}

export async function addAssetsToCollection(
  collectionId: string,
  assets: string[]
): Promise<{ slug: string }> {
  const response = await graphqlClient.request(
    gql`
      mutation ($collectionId: String!, $assets: [String]!) {
        addAssetsToCollection(collectionId: $collectionId, assets: $assets) {
          slug
        }
      }
    `,
    {
      collectionId,
      assets,
    }
  );

  return response.addAssetsToCollection;
}

export async function removeAssetsFromCollection(
  collectionId: string,
  assets: string[]
): Promise<{ slug: string }> {
  const response = await graphqlClient.request(
    gql`
      mutation ($collectionId: String!, $assets: [String]!) {
        removeAssetsFromCollection(
          collectionId: $collectionId
          assets: $assets
        ) {
          slug
        }
      }
    `,
    {
      collectionId,
      assets,
    }
  );

  return response.removeAssetsFromCollection;
}

export async function updateCollection(
  collectionId: string,
  title: string,
  description: string,
  longDescription: string,
  coverMediaCid: string,
  customOrder: string[]
): Promise<{ slug: string }> {
  const response = await graphqlClient.request(
    gql`
      mutation (
        $title: String!
        $description: String!
        $longDescription: String!
        $coverMediaCid: String!
        $collectionId: String!
        $customOrder: [String]!
      ) {
        updateCollection(
          title: $title
          description: $description
          coverMediaCid: $coverMediaCid
          longDescription: $longDescription
          collectionId: $collectionId
          customOrder: $customOrder
        ) {
          slug
        }
      }
    `,
    {
      collectionId,
      title,
      description,
      longDescription,
      coverMediaCid,
      customOrder,
    }
  );

  return response.updateCollection;
}

export async function deleteCollection(
  collectionId: string
): Promise<{ slug: string }> {
  const response = await graphqlClient.request(
    gql`
      mutation ($collectionId: String!) {
        deleteCollection(collectionId: $collectionId) {
          slug
        }
      }
    `,
    {
      collectionId,
    }
  );

  return response.deleteCollection;
}

export async function getAssetWithCollectionData(blockchainId: string) {
  const assetReq = await graphqlClient.request(
    gql`
      mutation ($blockchainId: String!) {
        asset: addViewToAsset(blockchainId: $blockchainId) {
          ${ExtendedAssetGql}
        }
      }
    `,
    { blockchainId }
  );

  return assetReq.asset;
}
