export interface ContractsInfo {
  chainId: number;
  chainName: string;
  etherscanUrl: string;
  assetContract: string;
  directSaleContract: string;
  proposalSaleContract: string;
  auctionSaleContract: string;
  marketplaceV1SaleContract: string;
}

export enum NotificationTypeEnum {
  TEXT = "TEXT",
  MINT = "MINT",
  ACCEPT_PROPOSAL = "ACCEPT_PROPOSAL",
  DIRECT_BUY = "DIRECT_BUY",
  NEW_PROPOSAL = "NEW_PROPOSAL",
  LOST_PROPOSAL = "LOST_PROPOSAL",
  FOLLOW_MINT = "FOLLOW_MINT",
  RECEIVED_ASSET = "RECEIVED_ASSET",
  AUCTION_SETTLE = "AUCTION_SETTLE",
  NEW_BID = "NEW_BID",
  AUCTION_ENDED = "AUCTION_ENDED",
}

export interface NotificationType {
  id: string;
  message: string;
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
  type: NotificationTypeEnum;
  payload: Record<string, unknown>;
}

export const NotificationGql = `
  id
  message
  isRead
  payload
  type
  createdAt
  updatedAt
`;

export type UserRole = "USER" | "CURATOR" | "CREATOR" | "ADMIN";

export interface PublicUserType {
  id: string;
  name: string;
  username: string;
  ethAddress: string;
  role: UserRole;
  avatarCid?: string;
  avatarColor?: string;
  hiddenAssetsFromGallery?: string[];
  followingCount: number;
  followedByCount: number;
  followingUser: boolean;
  createdAt: string;
  updatedAt: string;
}

export const PublicUserGql = `
  id
  name
  username
  ethAddress
  role
  createdAt
  updatedAt
  avatarCid
  avatarColor
  hiddenAssetsFromGallery
  followingCount
  followedByCount
  followingUser
`;

export interface PrivateUserType extends PublicUserType {
  email: string;
  allowMarketing: boolean;
}

export const PrivateUserGql = `
  ${PublicUserGql}
  email
  allowMarketing
`;

export interface CurrentUserType extends PrivateUserType {
  token: string;
  notifications: NotificationType[];
}

export const CurrentUserGql = `
  ${PrivateUserGql}

  notifications {
    ${NotificationGql}
  }
`;

export type ERCStandard = "ERC1155" | "ERC721";

export interface RegisteredContract {
  address: string;
  contractType: ERCStandard;
}

export const RegisteredContractsGql = `
 address
 contractType
`;

export interface UserShippingInfoType {
  address1: string;
  address2: string;
  country: string;
  phone: string;
  postcode: string;
  state: string;
}

export const UserShippingInfoGql = `
  address1
  address2
  country
  phone
  postcode
  state
`;

export interface UserGalleryType {
  userId: string;
  title?: string;
  coverMediaCid?: string;
  coverMediaType?: string;
  coverMediaTitle?: string;
  coverMediaDescription?: string;
  coverBackgroundColor?: string;
  coverForegroundColor?: string;
  bio?: string;
  twitter?: string;
  instagram?: string;
  website?: string;
  longDescription?: string;
  portraitImageCid?: string;
  additionalImageCids?: string[];
  coverCloudinaryId?: string;
  muxAssetId?: string;
  muxPlaybackId?: string;
}

export const UserGalleryGql = `
  title
  coverMediaCid
  coverMediaType
  coverMediaTitle
  coverMediaDescription
  coverBackgroundColor
  coverForegroundColor
  coverCloudinaryId
  muxAssetId
  muxPlaybackId
  bio
  twitter
  instagram
  website
  longDescription
  portraitImageCid
  additionalImageCids
`;

export type ListingStatusEnum = "open" | "sold" | "cancelled";

export type ListingTypeEnum = "direct" | "proposal" | "auction";

export interface ListingType {
  id: string;
  blockchainId: string;
  price: string;
  quantity: string;
  quantityTotal: string;
  seller: {
    user: PublicUserType;
  };
  status: ListingStatusEnum;
  createdAt: string;
  listingType: ListingTypeEnum;
  type: string;
  reservePrice: string;
  endTime: string;
  duration: string;
  asset: {
    id: any;
    assetId: string;
    contractAddress: string;
  };
}

export const ListingGql = `
  id
  blockchainId
  price
  quantity
  quantityTotal
  seller {
    user {
      ${PublicUserGql}
    }
  }
  status
  createdAt: timestamp
  listingType
  type
  reservePrice
  contractAddress
  bids (first: 1, , orderBy: timestamp, orderDirection: desc) {
    timestamp
     buyer {
       user {
         ${PublicUserGql}
      }
    }
  }
  asset {
   assetId
   contractAddress
  }
  endTime
  duration
`;

export enum ProposalStatusEnum {
  proposed = "proposed",
  accepted = "accepted",
  cancelled = "cancelled",
}

export interface ProposalType {
  id: string;
  blockchainId: string;
  seller: {
    user: PublicUserType;
  };
  buyer: {
    user: PublicUserType;
  };
  status: ProposalStatusEnum;
  price: string;
  quantity: string;
  createdAt: string;
  listing: {
    blockchainId: string;
    status: string;
  };
}

export const ProposalGql = `
  id
  blockchainId
  seller {
    user {
      ${PublicUserGql}
    }
  }
  buyer {
    user {
      ${PublicUserGql}
    }
  }
  listing {
    blockchainId
    status
  }
  price
  quantity
  status
  createdAt: timestamp
`;

export interface BidType {
  id: string;
  blockchainId: string;
  seller: {
    user: PublicUserType;
  };
  buyer: {
    user: PublicUserType;
  };
  price: string;
  createdAt: string;
  listing: {
    blockchainId: string;
    endTime: string;
    price: string;
    status: ListingStatusEnum;
  };
}

export const BidGql = `
  id
  blockchainId
  seller {
    user {
      ${PublicUserGql}
    }
  }
  buyer {
    user {
      ${PublicUserGql}
    }
  }
  listing {
    blockchainId
    endTime
    price
    status
  }
  listingStatus
  price
  createdAt: timestamp
`;

export interface SaleType {
  blockchainId: string;
  price: string;
  quantity: string;
  buyer: {
    user: PublicUserType;
  };
  seller: {
    user: PublicUserType;
  };
  status: string;
  createdAt: string;
  listingType: string;
  type: string;
}

export const SaleGql = `
  blockchainId
  price
  quantity
  buyer {
    user {
      ${PublicUserGql}
    }
  }
  seller {
    user {
      ${PublicUserGql}
    }
  }
  createdAt: timestamp
  listingType
  type
`;

export type AssetMetadataTrait = {
  key: string;
  value: boolean;
};

export type AssetMetadataAttribute = {
  label: string;
  value: string;
};

export interface AssetMetadataType {
  id: string;
  name: string;
  description: string;
  assetId: string;
  blockchainId: string;
  contractAddress: string;
  imageCid: string;
  mediaCid: string;
  mediaName: string;
  mediaSize: string;
  mediaType: string;
  animationUrl: string;
  imageUrl: string;
  metadataCid: string;
  metadataUrl: string;
  txHash: string;
  author: string;
  totalSupply: number;
  imagePreviewBlur: string;
  createdAt: string;
  downloadCid?: string;
  downloadName?: string;
  customLink?: string;
  customLinkText?: string;
  cloudinaryId?: string;
  muxPlaybackId?: string;
  attributes: AssetMetadataAttribute[];
  traits: AssetMetadataTrait[];
  additionalImageCids: string[];
  additionalImagePreviewBlurs: string[];
  views: number;
  likes: number;
  likedByCurrentUser: boolean;
  isReveal: boolean;
}

export type AssetTypeEnum =
  | "ARTWORK"
  | "PHYGITAL_ARTWORK"
  | "DIGITAL_COLLECTIBLE"
  | "PHYGITAL_COLLECTIBLE"
  | "EXTRA";

export interface AssetType {
  id: string;
  assetId: string;
  totalSupply: string;
  type: {
    name: AssetTypeEnum;
  };
  createdAt: string;
  totalSales: string;
  totalETHSales: string;
  totalOwners: string;
  metadata: AssetMetadataType;
  creatorUser: PublicUserType;
  balances: {
    account: {
      user: PublicUserType;
    };
  }[];
  listings: ListingType[];
  openListings: ListingType[];
  openProposals: ProposalType[];
  sales: SaleType[];
  collection: {
    title: string;
    slug: string;
  };
}

export const AssetMetadataGql = `
  id
  assetId
  blockchainId
  contractAddress
  name
  description
  imageCid
  imagePreviewBlur
  mediaCid
  mediaName
  mediaSize
  mediaType
  imageUrl
  animationUrl
  metadataCid
  metadataUrl
  author
  additionalImageCids
  additionalImagePreviewBlurs
  attributes
  downloadCid
  downloadName
  customLink
  customLinkText
  traits
  cloudinaryId
  muxAssetId
  muxPlaybackId
  txHash
  likes
  views
  likedByCurrentUser
  createdAt
  updatedAt
  isReveal
`;

/**
 * For the basic asset type we just need two balances,
 * to show the current holder, or two render "Multiple Owners"
 *
 * Same thing for listings. Show the current listing, and then
 * we can figure out to make the UI for multilisting.
 *
 * Same thing for the last sales.
 */
export const AssetGql = `
  id
  assetId
  totalSupply
  createdAt: timestamp
  totalETHSales
  totalSales
  totalOwners

  type {
    name
  }

  metadata {
    ${AssetMetadataGql}
  }

  creatorUser {
    ${PublicUserGql}
  }

  balances(first: 3, where: { value_gt: 0 }) {
    account {
      user {
        ${PublicUserGql}
      }
    }
  }

  openListings: listings(first: 2, where: { status: open }, orderBy: timestamp, orderDirection: desc) {
    ${ListingGql}
  }

  listings: listings(first: 2, orderBy: timestamp, orderDirection: desc) {
    ${ListingGql}
  }

  openProposals: proposals(first: 2, orderBy: price, orderDirection: desc) {
    ${ProposalGql}
  }

  sales(
    first: 1,
    orderBy: timestamp
    orderDirection: desc
  ) {
    ${SaleGql}
  }

  collection {
    title
    slug
  }
`;

export enum ShipmentStatusEnum {
  WAITING = "WAITING",
  SENT = "SENT",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export interface ShipmentType {
  id: string;
  asset: {
    creatorUser: { username: string };
    metadata: AssetMetadataType;
  };
  buyer: { user: { username: string; name: string; ethAddress: string } };
  buyerEmail: string;
  shippingInfo: UserShippingInfoType;
  quantity: string;
  status: ShipmentStatusEnum;
  createdAt: string;
  updatedAt: string;
}

export const ShipmentGql = `
  id
  asset {
    creatorUser {
      username
    }
    metadata {
      ${AssetMetadataGql}
    }
  }
  buyer {
    user {
      name
      username
      ethAddress
    }
  }
  buyerEmail
  shippingInfo {
    ${UserShippingInfoGql}
  }
  quantity
  status
  createdAt
  updatedAt
`;

export interface CollectionType {
  id: string;
  title: string;
  description: string;
  longDescription: string;
  slug: string;
  coverMediaCid: string;
  coverMediaType: string;
  coverCloudinaryId: string;
  muxAssetId: string;
  muxPlaybackId: string;
  createdAt: string;
  updatedAt: string;
  totalOwners: string;
  creator: PublicUserType;
  customOrder: string[];
  assets: AssetType[];
}

export const ExtendedAssetGql = `
  ${AssetGql}
  collection {
     title
     slug
     coverMediaCid
     coverMediaType
     coverCloudinaryId
     muxAssetId
     muxPlaybackId
     creator {
       ${PublicUserGql}
     }
   }
`;
export interface ExtendedAsset extends AssetType {
  collection: CollectionType;
}

export const CollectionGql = `
  id
  title
  description
  longDescription
  slug
  coverMediaCid
  coverMediaType
  coverCloudinaryId
  muxAssetId
  muxPlaybackId
  createdAt
  updatedAt
  totalOwners
  creator {
    ${PublicUserGql}
  }
  customOrder
  assets(customOrder: true) {
    ${AssetGql}
  }
`;
